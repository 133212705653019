export const defaultOptions = {
  typeElement: "span",
  more: false,
  less: false,
  number: false,
  nobutton: false,
  after: 0,
  btnClass: "show-more-btn",
  btnClassAppend: null,
};
